<template>
  <section class="box is-size-6">
		<b-modal
			v-model="isCardModalActive"
			has-modal-card
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-label="Example Modal"
			aria-modal>
			<template>
				<form ref="form">
					<div class="modal-card" style="width: auto">
						<header class="modal-card-head">
							<p class="modal-card-title is-size-6">Silahkan Unggah Berkas Anda</p>
							<button
								type="button"
								class="delete"
								@click="closeModal"/>
						</header>
						<section class="modal-card-body">
							<b-field label="Jenis Berkas*">
								<b-select
									:disabled="editedIndex > -1" 
									v-model="file_id" 
									placeholder="Jenis Berkas"
									validation-message="Silahkan pilih jenis berkas"
									@input="setFileTypeName"
									expanded
									required
								>
									<option 
										v-for="item in pendingFile"
										:value="item.ID_DP"
										:key="item.ID_DP"
									>	{{ item.NAMADOC }}
									</option>
								</b-select>
							</b-field>

						<b-field class="file">
							<b-upload 
								@input="onFilePicked"
								accept="application/pdf"
								required
								v-model="file" 
								expanded>
								<a class="button is-info is-fullwidth">
									<b-icon icon="upload"></b-icon>
									<span>{{ file.name || "Klik untuk unggah (Maksimal 1 MB)"}}</span>
								</a>
							</b-upload>
						</b-field>
						<div v-if="isISaku">
							<a @click="isProofModal = true"><u>Lihat Contoh Bukti Full Service i.saku</u></a>
						</div>
						</section>
						<footer class="modal-card-foot">
							<b-button
								label="Batal"
								@click="closeModal"
								:disabled="isLoading" />
							<b-button
								label="Unggah"
								type="is-info" 
								@click="upload"
								:loading="isLoading"/>
						</footer>
					</div>
				</form>
			</template>
		</b-modal>
		<b-modal
			v-model="isProofModal">
			<p class="image is-2by3">
				<img :src="urlISakuProof">
			</p>
		</b-modal>
		<b-notification					
			type="is-danger is-light is-size-7"
			role="alert"
			has-icon
			icon="alert-outline"
			:closable="false">
			<p>		
				Berikut berkas yang wajib anda unggah 
				<b>dalam bentuk pdf (Ukuran maksimal 1 MB)</b> :
			</p>
			<br>
			<ul>
				<li 
					v-for="item in filesType"
					:key="item.id"
				>
					{{item.NAMADOC}} -
					<span 
						:class="item.isDone == 1 
						? 'has-text-weight-semibold has-text-success'
						: 'has-text-weight-semibold'"
					>
						( {{ item.message }} )
					</span>
					<span
						v-if="(item.MANDATORYHRIS == 'X' && item.isDone != 1) && crossCheckISaku(item.NAMADOC)"
					>
						<b>Wajib</b>
					</span>
				</li>
			</ul>
			<br>
			<p><b>
				Jika sudah selesai mengupload semua berkas 
				dapat langsung menutup browser ini</b>
			</p>
		</b-notification>
		<b-field>
			<b-button  
				class="button is-success" 
				@click="isCardModalActive = true"
				expanded 
			>
				Unggah Berkas
			</b-button>
		</b-field>
		<b-table 
			class="box mt-6" 
			:data="submittedFile.length == 0 
				? empty : submittedFile"
		>
			<b-table-column
				label="Data Berkas"
				:visible="submittedFile.length == 0"
				v-slot="props" 
			>
				{{ props.row }}
			</b-table-column>
			<b-table-column
				:visible="submittedFile.length != 0"
				label="Jenis Berkas"
				v-slot="props" 
			>
				{{ props.row.NAMADOC }}
			</b-table-column>
			<b-table-column
				:visible="submittedFile.length != 0"
				field="action"
				label="Aksi"
				v-slot="props"
			>
				<div class="buttons">
					<b-button class="button is-info is-light"
						@click="generatePdf(props.row.filename)">
							<span class="icon">
								<i class="mdi mdi-eye"></i>
							</span>
						
						<!-- <b-button  
							class="button is-warning is-light" 
							@click="handleEdit(props.row)"
							icon-left="pencil"
							:disabled="props.row.is_disable"
						></b-button> -->
					</b-button>
				</div>
			</b-table-column>
		</b-table>
		<b-loading :is-full-page="false" v-model="refreshing" :can-cancel="false"></b-loading>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			isLoading: false,
			isCardModalActive: false,

			filesType: new Array(),
			pendingFile: new Array(),
			submittedFile: new Array(),
			applicantFileList: new Array(),
			tempFilesType: new Array(),
			alertListOfFileType: new Array(),

			file: [],
			file_id: "",
			fileTypeName: "",
			idFile: "",

			show: false,
			loadFinish: false,

			editedIndex: -1,
			empty: ["Belum ada berkas yang diunggah"],
			isProofModal: false,
			isISaku: false,
			urlISakuProof: null,
			refreshing: false,
			mandatoryISaku: false,
		}
	},
	created() {
		this.urlISakuProof = process.env.VUE_APP_DPP_IMAGES + '/isaku/contoh_full_service.png'
	},
	async mounted() {
		this.refreshing = true
		await this.fetchApplicantFileList();
    await this.fetchFilesType();
		await this.validateMandatoryISaku()
		this.refreshing = false
	},
	computed: {
		...mapGetters({
      applicant: "applicant"
    })
	},
	methods: {
		crossCheckISaku(tempFileTypeName) {
			const searchValues = ["isaku", "i.saku", "i-saku", "i saku"]
			// Normalize cases
			if (searchValues.some(value => tempFileTypeName.toLowerCase().includes(value.toLowerCase()))) {
				if (this.mandatoryISaku) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},

		async validateMandatoryISaku() {
			await this.$axios.get('applicants/' + this.applicant.id + `/mandatory-isaku`)
				.then(response => {
					this.mandatoryISaku = response.data.data
				})
				.catch(e => {
					this.message = "Error: " + e.response.data.message
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				})
		},
		setFileTypeName () {
			let selectedFile = this.filesType.filter(item => item.ID_DP == this.file_id)
			this.fileTypeName = selectedFile[0].NAMADOC

			const searchValues = ["isaku", "i.saku", "i-saku", "i saku"]
			// Normalize cases
			if (searchValues.some(value => this.fileTypeName.toLowerCase().includes(value.toLowerCase()))) {
				this.isISaku = true
			} else {
				this.isISaku = false
			}
		},

		async upload() {
      this.isLoading = true;
			
			const formData = new FormData();
      formData.set("file_id", this.file_id);
			formData.append("file", this.file);
			formData.append("fileTypeName", this.fileTypeName);

      if (this.$refs.form.checkValidity()) {
        try {
          if (this.editedIndex > -1) {
            // ! Update logic
						await this.$axios.post(
							process.env.VUE_APP_FILE_URL + this.idFile + `/update`, formData);
						
						this.$buefy.snackbar.open({
							message: "Berkas berhasil di simpan",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          }
          // ! store logic
					else
            await this.$axios.post(
              process.env.VUE_APP_FILE_URL + this.applicant.id + `/upload`,
              formData
            );

						this.$buefy.snackbar.open({
							message: "Berkas berhasil di simpan",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})

						this.pendingFile = [];
						this.submittedFile = [];
						await this.fetchApplicantFileList();
						await this.fetchFilesType();
        } catch (error) {
          console.log(error);
		if (error.response.status === 401) {
			this.$buefy.snackbar.open({
						message: "Error: " + error.response.data.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
		} else {
			this.$buefy.snackbar.open({
						message: "Berkas gagal diunggah",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
		}
          
        }

				this.resetForm();

        this.isLoading = false;
        this.isCardModalActive = false;
        this.editedIndex = -1;
      } else {
				this.isLoading = false;
				this.$buefy.snackbar.open({
					message: "Silahkan pilih jenis berkas dan unggah file",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
      }
    },
		async fetchApplicantFileList() {
      try {
        this.isLoading = true;
        const response = await this.$axios.get(
          `files/applicant/` + this.applicant.id
        );
        this.applicantFileList = response.data.data;

        this.isLoading = false;
      } catch (error) {
        console.log(error.response.data.message);
      }

      this.isLoading = false;
    },

    async fetchFilesType() {
      try {
        let response = await this.$axios.get(`files/` + this.applicant.id);
				this.filesType = response.data.data;
        this.filesType.map(el1 => {
          let temp = this.applicantFileList.filter(
            el2 => el2.id_dp == el1.ID_DP
          );

          if (temp.length == 0) {
            el1.isDone = false;
						el1.message = "Belum";
          } else {
            el1.isDone = true;
            el1.message = "Sudah";
			el1.filename = temp[0].name
					}

					if (el1.isDone == false)
						this.pendingFile.push(el1)
					else if (el1.isDone == true)
						this.submittedFile.push(el1)
				})
				// this.filesType[6].name = "Surat Lamaran Kerja"
				// this.filesType[7].name = "Surat Keterangan Lulus (SKL)"
      } catch (error) {
        console.log(error.response.data.message);
      }
		},
		
		deleteDropFile(index) {
      this.file.splice(index, 1);
    },

		onFilePicked(file) {
			const files = file
			if (files.size > 1000000) {
				this.$buefy.snackbar.open({
					message: 'Ukuran file terlalu besar, maksimal 1 megabytes',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				
				this.resetForm()
			} else {
				this.file = files
			}
		},

		resetForm() {
			this.file = []
			this.isCardModalActive = false
			this.file_id = ''
		},

		async generatePdf(name) {
			await this.$axios
				.get("files/pdf/" + name, {responseType: 'arraybuffer', dataType: 'blob', headers : {'Cache-Control' : 'no-cache, no-store'}}) // works on chrome, firefox, ms edge, internet explorer
				.then(response => {
					//handle kembalian file pdf
					let blob=new Blob([response.data], {type: 'application/pdf'});
					// if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for internet explorer
					// 	window.navigator.msSaveOrOpenBlob(blob);
					// 	return;
					// }
					let link=document.createElement('a');
					link.href=window.URL.createObjectURL(blob);
					link.target="_blank";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(link.href)
							, 100
					})
				})
				.catch(async e => {
					let converter = require('arraybuffer-to-string');
					let convertResponse = converter(e.response.data); //terjemahkan response arraybuffer ke string
					let tempMessage = JSON.parse(convertResponse);
					console.log(tempMessage);
					this.message = "Error: " + tempMessage.message;
					this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
								})
					return false
					});
		},

		closeModal() {
			this.isCardModalActive = false;
			this.resetForm();
      this.editedIndex = -1;
		},
		
    // async handleEdit(item) {
    //   this.editedIndex = await this.applicantFileList.indexOf(item);

    //   this.idFile = item.id;
    //   this.file_id = item.file_id;

    //   this.isCardModalActive = true;
    // },
	},
}
</script>